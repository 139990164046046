// .core
import React, { useCallback, useState } from 'react'
// components
import { Content, Loader, Toast } from 'components'
// libraries
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom'
// layout
// import { FallbackPage } from 'pages/misc/FallbackPage/FallbackPage';
// services
import { AuthService } from 'services/AuthService'
// styles
import './styles/index.scss'
import { ROUTES_AUTH, ROUTES_CORE } from './pages/routes'
import { PageNotFound } from 'pages/misc/PageNotFound/PageNotFound'
import { observer } from 'mobx-react'
import css from './components/layout/Layout.module.scss'
import { MainMenu } from './components/layout/MainMenu/MainMenu'
import { ReactComponent as MenuIcon } from './assets/icons/svg/menu-icon.svg'
import { UserAvatar } from './components/layout/UserAvatar/UserAvatar'
import { SettingsService } from './services/SettingsService'
// import { sendPageView, setUser } from './utils/analytics';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Chatbot } from 'components/complex/Chatbot/Chatbot'
import { DownloadsService } from 'services/DownloadsService'
import { cookieOptions } from 'cookieOptions'
// declarations
const DEF_UI_CONTEXT = { bIsSideMenuOpen: false }
export const GlobalUIContext = React.createContext(DEF_UI_CONTEXT)

let hasBugSnag = false

if (process.env.REACT_APP_BUGSNAG_KEY && process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
  })
  hasBugSnag = true
}

interface IAppState {
  bIsSideMenuOpen: boolean
}

@observer
export default class App extends React.Component<{}, IAppState> {
  constructor(props: {}) {
    super(props)
    // AuthService.logIn({ email: 'test@edvordo.sk', password: 'qwertyuiop', rememberMe: true })
    AuthService.checkUser()
    SettingsService.loadSettings()
  }

  // mounted lifecycle
  componentDidMount() {
    try {
      const cookieConsent = (window as any).initCookieConsent();
      cookieConsent?.run({ ...cookieOptions });
    } catch (error) {
      console.error("Error initializing cookie consent:", error);
    }
  }

  render() {
    const { checkingForUser } = AuthService

    if (checkingForUser) {
      return <Loader.Branded />
    }

    return (
      <>
        <BrowserRouter>{AuthService.bIsLoggedIn ? <LayoutCore /> : <LayoutAuth />}</BrowserRouter>
        <Toast />
      </>
    )
  }
}

export interface ILayoutProps {
  onToggleSideMenu?(bIsSideMenuOpen: boolean): void
}

/**
 * Navigation component of routes for non-logged in user
 */
const LayoutAuth = () => (
  <Switch>
    {ROUTES_AUTH.map(page => (
      <Route key={page.path} path={'/' + page.path} component={page.component} />
    ))}

    <PageNotFound />
  </Switch>
)

/**
 * Navigation component of routes for logged and authentificated user
 */
const LayoutCore = observer(() => {
  const [show, setShow] = useState<boolean>(false)
  const history = useHistory()

  history.listen(() => {
    if (AuthService.currentUser) {
      if (hasBugSnag) {
        Bugsnag.setUser(String(AuthService.currentUser.id))
      }
      // setUser(String(AuthService.currentUser.id))
    }
    // sendPageView(h)
  })

  const handleMenuClick = useCallback(
    (show: boolean) => {
      setShow(show)
    },
    [setShow]
  )

  const renderCore = () => (
    <Switch>
      {ROUTES_CORE.map(page => (
        <Route
          key={page.path}
          exact={page.exact}
          path={'/' + page.path}
          component={page.component}
        />
      ))}
      <PageNotFound />
    </Switch>
  )

  const openMenu = () => {
    handleMenuClick(true)
  }

  return (
    <div className={css.root}>
      <MainMenu showMenu={show} handleMenuClick={handleMenuClick} />

      <MenuIcon className={css.menuIcon} onClick={openMenu} />

      {
      !AuthService.currentUser?.owen.is_complete
       ? !DownloadsService.isDownloadPage && <Chatbot /> : null}

      <UserAvatar />
      <Content>
        <Route render={renderCore} />
      </Content>
    </div>
  )
});
